import axios from "axios";

const state = {
  isAuthenticated: null,
  accessToken: null,
  workspaceToken: null,
  user: null,
  currentWorkspace: {
    name: null,
    role: null,
    currency: null,
    time_zone: null,
  },
  workspaces: [],
  email: null,
};

const mutations = {
  SET_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = accessToken;
  },
  SET_WORKSPACE_TOKEN(state, workspaceToken) {
    state.workspaceToken = workspaceToken;
  },
  SET_IS_AUTHENTICATED(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_CURRENT_WORKSPACE(state, currentWorkspace) {
    state.currentWorkspace.name = currentWorkspace.name;
    state.currentWorkspace.role = currentWorkspace.role;
    state.currentWorkspace.currency = currentWorkspace.currency;
    state.currentWorkspace.time_zone = currentWorkspace.time_zone;
  },
  SET_USER_EMAIL(state, email) {
    state.email = email;
  },
};

const actions = {
  setWorkspaceToken({ commit }, workspaceToken) {
    commit("SET_WORKSPACE_TOKEN", workspaceToken);
  },

  setIsAuthenticated({ commit }, isAuthenticated) {
    commit("SET_IS_AUTHENTICATED", isAuthenticated);
  },

  setAccessToken({ commit }, accessToken) {
    commit("SET_ACCESS_TOKEN", accessToken);
    console.log("auth", accessToken);
  },

  initialize({ commit }) {
    console.log("Log In Upon entry");
    if (localStorage.getItem("auth-access-token"))
      commit("SET_ACCESS_TOKEN", localStorage.getItem("auth-access-token"));

    if (localStorage.getItem("auth-workspace-token"))
      commit(
        "SET_WORKSPACE_TOKEN",
        localStorage.getItem("auth-workspace-token")
      );
  },

  async localLogIn({ commit }, { email, password } = {}) {
    try {
      const response = await axios.post("/admin/login", {
        email,
        password,
      });
      if (response.status == 200) {
        console.log(response.data);
        commit("SET_IS_AUTHENTICATED", true);
        localStorage.setItem("auth-access-token", response.data.access_token);
        commit("SET_ACCESS_TOKEN", response.data.access_token);
        commit("SET_USER", {
          member_of: response.data.member_of,
          name: response.data.name,
        });
        commit("SET_USER_EMAIL", email);
      }
      return response;
    } catch (error) {
      console.log(error.response);
      throw error;
    }
  },

  async workspaceLogin({ commit }, business_id) {
    try {
      console.log("business_id from store", business_id);
      const response = await axios.post("/login/workspace", {
        businessId: business_id,
      });
      if (response.status == 200) {
        // setTimeout(async () => {
        console.log(response.data);
        commit("SET_WORKSPACE_TOKEN", response.data.workspaceToken);
        // commit("SET_CURRENT_WORKSPACE", response.data.name);
        localStorage.setItem(
          "auth-workspace-token",
          response.data.workspaceToken
        );

        // Get current
        const profile = await axios.get("/auth/me");
        const current_role = profile.data.member_of.filter((business) => {
          return response.data.info._id == business.business_id;
        })[0].role;
        response.data.info.role = current_role;
        commit("SET_CURRENT_WORKSPACE", response.data.info);
        // location.replace("/");
        // }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  },

  logOut({ commit }) {
    commit("SET_IS_AUTHENTICATED", false);
    commit("SET_ACCESS_TOKEN", null);
    localStorage.clear();

    console.table({
      AccessTOken: this.accessToken,
      AuthStatus: this.isAuthenticated,
    });
  },
};

const getters = {
  getAccessToken: (state) => state.accessToken,
  getWorkspaceToken: (state) => state.workspaceToken,
  getIsAuthenticated: (state) => state.isAuthenticated,
  getDefaultBusinessId: (state) => {
    console.log({ length: state.user.member_of.length });
    if (state.user.member_of.length < 1) {
      return null;
    } else {
      return state.user.member_of[0].business_id;
    }
  },
  getCurrentWorkspace: (state) => state.currentWorkspace,
  getEmail: (state) => state.email,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
