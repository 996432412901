import Vue from 'vue'
import Axios from "axios";
import App from './App.vue'
import store from "./store";
import router from "./router";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { baseURL } from "./services/config";


Axios.defaults.baseURL = baseURL;
Axios.defaults.headers.common.Accept = "application/json";
Axios.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem("figa_access_token");
      if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

      return config;
    },
    function(err) {
      return Promise.reject(err);
    }
);

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
