import Vue from "vue";
import Vuex from "vuex";
import persistStore from 'vuex-persistedstate';
import auth from './auth';
import general from './general';


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        general},
    plugins: [persistStore({
        paths: ['auth'],
    })]
});
