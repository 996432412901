import Vue from "vue";
import VueRouter from "vue-router";
// import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    path: "/dashboard",
    name: "Customers & Business Management", 
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/Register.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "password-forgot" */ "@/views/ForgotPassword.vue"
      ),
  },
  {
    path: "/administrators",
    name: "Administrators",
    component: () =>
      import(
        /* webpackChunkName: "navbar" */ "@/views/Administrators.vue"
      ),
  },
  {
    path: "/account",
    name: "Account Settings",
    component: () =>
      import(
        /* webpackChunkName: "navbar" */ "@/views/Account.vue"
      ),
  },
  {
    path: "/customerDetails/:id",
    name: "Customer Details",
    component: () =>
      import(
        /* webpackChunkName: "navbar" */ "@/views/CustomerDetails.vue"
      ),
  },
  {
    path: "/navbar",
    name: "NavBar",
    component: () =>
      import(
        /* webpackChunkName: "navbar" */ "@/components/common/NavBar.vue"
      ),
  },
  {
    path: "/topbar",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "topbar" */ "@/components/common/TopBar.vue"
      ),
  },
  {
    path: "/home-layout",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "home-layout" */ "@/layout/HomeLayout.vue"
      ),
  },
  {
    path: "/coupons",
    name: "Coupons",
    component: () =>
      import(
        /* webpackChunkName: "navbar" */ "@/views/Coupons.vue"
      ),
  },
];

// function authRequired(to, from, next) {
//   if (!store.getters["auth/getIsAuthenticated"]) {
//     next("/login");
//   } else next();
// }

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
